import { usePlayer } from 'lib/hooks/use-player'
import FullPlayerSkeleton from './FullPlayerSkeleton'
import Queue from './Queue'
import FullPlayer from './FullPlayer'
import { MouseEvent, useState } from 'react'
import IconButton from 'components/ui/IconButton'
import ChevronDown from 'components/icons/ChevronDown'
import Close from 'components/icons/Close'
import PlaylistPlay from 'components/icons/PlaylistPlay'

const PlayerContainer = ({
  onClick,
}: {
  onClick?: (event?: MouseEvent) => void
}) => {
  const [showPlaylist, setShowPlaylist] = useState(false)

  const { current } = usePlayer()

  if (!current) return <FullPlayerSkeleton />

  return (
    <>
      {onClick && (
        <div className="close-button" aria-label="Minimize Player">
          <IconButton
            onClick={onClick}
            ariaLabel="Minimize player"
            variant="secondary"
          >
            <ChevronDown fill="var(--secondary)" width="32" height="32" />
          </IconButton>
        </div>
      )}

      {showPlaylist && (
        <div className="queue-title" aria-label="Queue title">
          播放列表
        </div>
      )}


      {showPlaylist && (
        <div className="close-queue-button" aria-label="Close queue">
          <IconButton
            onClick={() => setShowPlaylist(false)}
            ariaLabel="Close queue"
            variant="secondary"
          >
            <Close fill="var(--secondary)" width="32" height="32" />
          </IconButton>
        </div>
      )}

      {showPlaylist ? (
        <Queue />
      ) : (
        <FullPlayer>
          <button
            onClick={() => setShowPlaylist(true)}
            className="button playing-next-button"
            tabIndex={current ? 6 : -1}
            aria-hidden={!current}
          >
            <PlaylistPlay width={44} height={44} />
          </button>
        </FullPlayer>
      )}

      <style jsx>{`
        .button {
          transform: scale(1);
          transition: transform 0.1s;
        }
        .button:hover {
          transform: scale(1.025);
        }

        .playing-next-button {
          height: 3.5rem;
          width: 3.5rem;
          color: var(--secondary);
          background: transparent;
          outline: none;
          border: none;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          border-radius: 50%;
          line-height: 0.5;
          padding: 0.625rem;
          font-size: var(--font-xl);
        }

        .playing-next-button:hover {
          transition: transform 0.1s, background-color 0.2s;
          background: var(--primary-95);
        }

        .close-button {
          z-index: 3;
          position: absolute;
          left: 1rem;
          top: 1.5rem;
        }

        .queue-title {
          z-index: 3;
          position: absolute;
          top: 1.5rem;
          left: 50%;
          transform: translateX(-50%);
          right: 0;
          margin: 0 auto;
          color: white;
          font-weight: bold;
          cursor: pointer;
          display: flex;
          justify-content: center;
          padding: 1rem 0 0.5rem;
          font-size: var(--font-xl);
        }

        .close-queue-button {
          z-index: 3;
          position: absolute;
          right: 1rem;
          top: 1.5rem;
        }
      `}</style>
    </>
  )
}

export default PlayerContainer
