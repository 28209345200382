import PropTypes from 'prop-types';
import { useState, useContext } from 'react';
import { PlayerContext } from 'lib/hooks/use-player';

export const PlaybackRateSelector = ({ rates }) => {
  const playerContext = useContext(PlayerContext);
  const [showOptions, setShowOptions] = useState(false);

  if (!playerContext) {
    throw new Error('PlaybackRateSelector must be used within a PlayerProvider');
  }

  const { playbackRate, setPlaybackRate } = playerContext;

  return (
    <div className="playback-rate-selector-container">
      <button
        className="playback-rate-button"
        onClick={() => setShowOptions(!showOptions)}
        aria-label="Change playback rate"
        tabIndex={0}
      >
        {playbackRate}
      </button>

      {showOptions && (
        <ul className="playback-rate-options">
          {rates.map((rate) => (
            <li
              key={rate}
              className={`playback-rate-option ${rate === playbackRate ? 'selected' : ''}`}
              onClick={() => {
                setPlaybackRate(rate);
                setShowOptions(false);
              }}
            >
              {rate}x
            </li>
          ))}
        </ul>
      )}

      <style jsx>{`
        .playback-rate-selector-container {
          position: relative;
        }

        .playback-rate-button {
          height: 3.5rem;
          width: 3.5rem;
          color: var(--secondary);
          background: transparent;
          outline: none;
          border: none;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          border-radius: 50%;
          line-height: 0.5;
          padding: 0.625rem;
          font-size: var(--font-xl);
        }

        .playback-rate-button:focus,
        .playback-rate-button:hover {
          background: var(--primary-95);
        }

        .playback-rate-button::after {
          content: 'x';
          font-size: var(--font-md);
        }

        .playback-rate-options {
          list-style: none;
          padding: 0.5rem 0;
          background: #333;
          position: absolute;
          right: 0;
          bottom: 100%;
          border-radius: 5px;
          min-width: 100%;
          z-index: 1000;
          pointer-events: auto;
        }

        .playback-rate-option {
          padding: 0.5rem 1rem;
          color: white;
          cursor: pointer;
          text-align: center;
          pointer-events: auto;
        }

        .playback-rate-option.selected {
          background-color: rgba(255, 255, 255, 0.3);
        }
        .playback-rate-option:hover,
        .playback-rate-option:focus {
          background-color: rgba(255, 255, 255, 0.1);
        }
      `}</style>
    </div>
  );
};

PlaybackRateSelector.propTypes = {
  rates: PropTypes.array.isRequired,
};
