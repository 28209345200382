export const getFormattedDate = (dateToformat: Date | string | number, timeZone = 'Asia/Shanghai'): string => {
  const date = new Date(dateToformat);
  const formatter = new Intl.DateTimeFormat('zh-CN', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
    timeZone: timeZone
  });

  const formattedDate = formatter.format(date).replace(/\//g, '-').replace(', ', ' ');
  return formattedDate;
};
